import { FC, useEffect } from 'react';
import Image from 'next/image';
import { useSearchParams } from 'next/navigation';
import Cookies from 'js-cookie';
import { isEmpty } from 'radash';
import { RegisterBannerType } from '@modules/libs/graphql/generated/graphql';
import { registerBannerStore } from '@modules/registerBanner/adapter/outbound/stores/registerBanner.store';
import { Content } from '@components/layouts';
import breakpoint from '@shared/assets/breakpoint.json';
import { getAuthCookiesName } from '@shared/helpers/getAuthCookiesName.helper';
import { getUtmParams } from '@shared/helpers/getUtmParams.helper';
import { useWindowSize } from '@shared/utils/hooks';
import { useUtag } from '@shared/utils/hooks/useUtag.utils';
import { BannerRegister, ImgBanner, ReflexShadow } from './RegisterBanner.style';
const RegisterBannerLogic: FC = () => {
  const utag = useUtag();
  const {
    width
  } = useWindowSize();
  const token = Cookies.get(getAuthCookiesName());
  const {
    registerBanner,
    setRegisterBanner
  } = registerBannerStore(state => state);
  const searchParams = useSearchParams();
  const defaultUtmParams = {
    campaign: 'CPBrandsite',
    source: 'Website_Direct',
    medium: 'MainNavButton'
  };
  useEffect(() => {
    if (token) {
      setRegisterBanner(RegisterBannerType.BannerPlayGame);
    } else {
      setRegisterBanner(RegisterBannerType.BannerRegister);
    }
  }, [token]);
  const handleOnClickTracker = (title: string) => {
    utag.link({
      tealium_event: 'banner_register_click',
      banner_name: title
    });
  };
  return !isEmpty(registerBanner) && <Content>
                <BannerRegister onClick={() => {
      handleOnClickTracker('banner_register');
    }}>
                    <a href={`${registerBanner.linkOutUrl}?${getUtmParams(searchParams, defaultUtmParams)}`} target="_blank" aria-label="register banner">
                        <ImgBanner src={width > breakpoint.md - 1 ? registerBanner.bannerDesktop : registerBanner.bannerMobile} width={1140} height={440} alt={registerBanner.title} />
                        <ReflexShadow>
                            <Image src={width > breakpoint.md - 1 ? registerBanner.bannerDesktop : registerBanner.bannerMobile} width={1140} height={440} alt={registerBanner.title} />
                        </ReflexShadow>
                    </a>
                </BannerRegister>
            </Content>;
};
export default RegisterBannerLogic;